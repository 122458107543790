import { Schema as S } from "effect";
import { UTCDateTime } from "./UTCDateTime";

export class LoadCurve extends S.Class<LoadCurve>("LoadCurve")({
  values: S.Array(
    S.Struct({
      start: UTCDateTime,
      end: UTCDateTime,
      v: S.Number,
    })
  ),
  totalConsumptionWh: S.Number,
  prmCount: S.Number,
}) {
  get length() {
    return this.values.length;
  }

  asObject(step: "starting" | "ending") {
    return this.values.reduce((acc, { start, end, v }) => {
      const key = (step === "starting" ? start : end).formatISO();
      acc[key] = v;
      return acc;
    }, {} as Record<string, number>);
  }

  static empty() {
    return new LoadCurve({
      values: [],
      totalConsumptionWh: 0,
      prmCount: 0,
    });
  }
}
