import { ParseResult } from "effect";
import { Schema as S } from "effect";
import { pipe } from "effect";
import { DateTime } from "luxon";
import type { Granularity } from "./LocalDateTime";
import { LocalDateTime } from "./LocalDateTime";

export const validLocalDateTime = S.transformOrFail(S.String, S.String, {
  decode: (s) => {
    try {
      LocalDateTime.buildFromString(s);
      return ParseResult.succeed(s);
    } catch (e) {
      return ParseResult.fail(new ParseResult.Unexpected(s));
    }
  },
  encode: ParseResult.succeed,
});

const props = {
  localDateTimeString: pipe(S.String, S.compose(validLocalDateTime)),
};

export class LDateTime extends S.Class<LDateTime>("LDateTime")(props) {
  format(dateFnsFormat?: string) {
    return this.localDateTime.format(dateFnsFormat);
  }
  get localDateTime() {
    return LocalDateTime.buildFromString(this.localDateTimeString);
  }

  get luxonDateTime() {
    return DateTime.fromISO(this.asUtcDateString());
  }

  asUtcDateString() {
    return this.localDateTime.asUtcDateString();
  }

  static fromLocalDateTime(l: LocalDateTime<any>) {
    return new LDateTime({ localDateTimeString: l.format() });
  }

  get granularity() {
    return this.localDateTime.granularity;
  }

  diff(other: LDateTime) {
    return this.luxonDateTime.diff(other.luxonDateTime);
  }
}

export class MonthLDateTime extends S.Class<MonthLDateTime>("MonthLDateTime")(
  props
) {
  format(dateFnsFormat?: string, locale?: "fr" | "en" | undefined) {
    return this.localDateTime.format(dateFnsFormat, locale);
  }
  get localDateTime() {
    return LocalDateTime.buildFromString(this.localDateTimeString);
  }

  asUtcDateString() {
    return this.localDateTime.asUtcDateString();
  }

  static fromLocalDateTime(l: LocalDateTime<"month">) {
    return new LDateTime({ localDateTimeString: l.format() });
  }

  get luxonDateTime() {
    return DateTime.fromISO(this.asUtcDateString());
  }

  get granularity() {
    return this.localDateTime.granularity;
  }
}

export class DayLDateTime extends S.Class<DayLDateTime>("DayLDateTime")(props) {
  format(dateFnsFormat?: string, locale?: "fr" | "en" | undefined) {
    return this.localDateTime.format(dateFnsFormat, locale);
  }
  get localDateTime() {
    return LocalDateTime.buildFromString(this.localDateTimeString);
  }

  asUtcDateString() {
    return this.localDateTime.asUtcDateString();
  }

  get granularity() {
    return this.localDateTime.granularity;
  }

  get luxonUtcDateTime() {
    return DateTime.fromISO(this.asUtcDateString());
  }

  luxonDateTimeInZone(zone: string) {
    return DateTime.fromFormat(this.localDateTimeString, "yyyy-MM-dd", {
      zone,
    });
  }

  static fromString(s: string) {
    return DayLDateTime.fromISOLuxon(DateTime.fromISO(s.slice(0, 10)));
  }

  static fromISOLuxon(dateTime: DateTime) {
    return DayLDateTime.fromLocalDateTime(
      LocalDateTime.buildFromString(
        dateTime.toISO()!.slice(0, 10),
        "day"
      ) as LocalDateTime<"day">
    );
  }
  static fromLocalDateTime(l: LocalDateTime<"day">) {
    return new DayLDateTime({ localDateTimeString: l.format() });
  }

  diff(other: DayLDateTime) {
    return this.luxonUtcDateTime.diff(other.luxonUtcDateTime);
  }

  min(other: DayLDateTime) {
    return pipe(
      DateTime.min(this.luxonUtcDateTime, other.luxonUtcDateTime),
      DayLDateTime.fromISOLuxon
    );
  }

  max(other: DayLDateTime) {
    return pipe(
      DateTime.max(this.luxonUtcDateTime, other.luxonUtcDateTime),
      DayLDateTime.fromISOLuxon
    );
  }
}

export namespace LDateTime {
  export const fromString = (s: string) =>
    LDateTime.fromLocalDateTime(LocalDateTime.buildFromString(s));

  export namespace schemas {
    export const fromString = (format?: string, g?: Granularity) =>
      pipe(
        S.String,
        S.transformOrFail(LDateTime, {
          decode: (s, _options, ast) => {
            try {
              return ParseResult.succeed(
                new LDateTime({
                  localDateTimeString: LocalDateTime.buildFromString(
                    s,
                    g,
                    format
                  ).format(),
                })
              );
            } catch (e) {
              return ParseResult.fail(
                new ParseResult.Type(
                  ast,
                  "LDateTime",
                  `should be a date matching ${format}, got ${s}`
                )
              );
            }
          },
          encode: ({ localDateTimeString }) =>
            ParseResult.succeed(localDateTimeString),
        })
      );

    export const fromStringWithGranularity = (
      g: Granularity,
      format?: string
    ) => fromString(format, g);

    export const month = pipe(
      S.String,
      S.transformOrFail(MonthLDateTime, {
        decode: (s, _options, ast) => {
          try {
            return ParseResult.succeed(
              new MonthLDateTime({
                localDateTimeString: LocalDateTime.buildFromString(
                  s,
                  "month"
                ).format(),
              })
            );
          } catch (e) {
            return ParseResult.fail(
              new ParseResult.Type(
                ast,
                "LDateTime",
                `should be a date matching month format, got ${s}`
              )
            );
          }
        },
        encode: ({ localDateTimeString }) =>
          ParseResult.succeed(localDateTimeString),
      })
    );

    export const day = pipe(
      S.String,
      S.transformOrFail(DayLDateTime, {
        decode: (s, _options, ast) => {
          try {
            return ParseResult.succeed(
              new DayLDateTime({
                localDateTimeString: LocalDateTime.buildFromString(
                  s,
                  "day"
                ).format(),
              })
            );
          } catch (e) {
            return ParseResult.fail(
              new ParseResult.Type(
                ast,
                "LDateTime",
                `should be a date matching month format, got ${s}`
              )
            );
          }
        },
        encode: ({ localDateTimeString }) =>
          ParseResult.succeed(localDateTimeString),
      })
    );
  }
}
