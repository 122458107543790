import { Schema as S } from "effect";
import * as fns from "date-fns";
import { format as formatTz, utcToZonedTime } from "date-fns-tz";
import { pipe } from "effect";
import type { DurationLikeObject } from "luxon";
import { DateTime, Duration } from "luxon";
import fr from "date-fns/locale/fr/index.js";

export type Granularity =
  | "year"
  | "month"
  | "day"
  | "hour"
  | "minute"
  | "second"
  | "millisecond";

type MillisecondOfBelow = "millisecond";
type SecondOrBelow = MillisecondOfBelow | "second";
type MinuteOrBelow = SecondOrBelow | "minute";
type HourOrBelow = MinuteOrBelow | "hour";
type DayOrBelow = HourOrBelow | "day";
type MonthOrBelow = DayOrBelow | "month";
type YearOrBelow = Granularity;

type OrBelow<G extends Granularity> = G extends "year"
  ? YearOrBelow
  : G extends "month"
  ? MonthOrBelow
  : G extends "day"
  ? DayOrBelow
  : G extends "hour"
  ? HourOrBelow
  : G extends "minute"
  ? MinuteOrBelow
  : G extends "second"
  ? SecondOrBelow
  : MillisecondOfBelow;

type Digit = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
type ZeroToFive = 0 | 1 | 2 | 3 | 4 | 5;

type YearDate = `${number}`;
type MonthDate = `${YearDate}-${number}`;
type DayDate = `${MonthDate}-${number}`;
type HourDate = `${DayDate}T${number}`;
type MinuteDate = `${HourDate}:${number}`;
type SecondDate = `${MinuteDate}:${ZeroToFive}${Digit}`;
type MillisecondDate = `${SecondDate}.${number}`;
type StringDateInput =
  | YearDate
  | MonthDate
  | DayDate
  | HourDate
  | MinuteDate
  | SecondDate
  | MillisecondDate;

const DATE_REGEXP =
  /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d)|(\d{4}-[01]\d-[0-3]\d)|(\d{4}-[01]\d)|\d{4}-[01]\d|\d{4}/;

const isStringDateAsUtc = (s: string) => DATE_REGEXP.test(s);

const lengthToGranularity = (l: number): Granularity => {
  switch (l) {
    case 4:
      return "year";
    case 7:
      return "month";
    case 10:
      return "day";
    case 13:
      return "hour";
    case 16:
      return "minute";
    case 19:
      return "second";
    case 23:
      return "millisecond";
    default:
      throw new Error("Invalid date string length to get granularity");
  }
};

export const granularityToLength = (g: Granularity): number => {
  switch (g) {
    case "year":
      return 4;
    case "month":
      return 7;
    case "day":
      return 10;
    case "hour":
      return 13;
    case "minute":
      return 16;
    case "second":
      return 19;
    case "millisecond":
      return 23;
    default:
      throw new Error("Never happens");
  }
};

const isValid = (s: string, format?: string) => {
  if (format) {
    return fns.isValid(fns.parse(s, format, new Date()));
  } else {
    return fns.isValid(fns.parseISO(s));
  }
};
const mergeStrings = (defaultString: string, dateString: string) => {
  const defaultStringAsArray = defaultString.split("");
  const dateStringAsArray = dateString.split("");
  const merged = defaultStringAsArray.map((c, i) =>
    dateStringAsArray[i] ? dateStringAsArray[i] : c
  );
  return merged.join("");
};

const mergeWithDefaultDateString = (dateString: string) =>
  mergeStrings("1970-01-01T00:00:00.000", dateString);

const D_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
export class LocalDateTime<G extends Granularity> {
  /**
   * This is a UTC (Z) representation of the local date
   * We only use Z to make sure we don't have any timezone issues,
   * and to be able to use date-fns
   *
   * There is no bijection between this and some amount of milliseconds since epoch
   * It does not represent an instant
   * It cannot be converted to a timestamp, or created from a timestamp
   * It's not a Date :)
   *
   * It's the representation of a local date.
   * It's good for representing things like "the 1st of January 2021 at 14:00", but without mentioning the timezone,
   * meaning the instant will be different depending on the timezone you're talking about.
   *
   * Operations on this will be done without any notion of daylight saving, etc.
   *
   */
  private readonly d: string;
  private readonly g: G;

  private constructor(utcDateOrString: string, granularity: G) {
    if (isStringDateAsUtc(utcDateOrString)) {
      this.d = formatTz(
        fns.parseISO(mergeWithDefaultDateString(utcDateOrString)),
        D_FORMAT,
        {
          timeZone: "UTC",
        }
      );
      this.g = granularity;
    } else {
      throw new Error("Invalid date string");
    }
  }

  static parse(dateFnsSchema: string, input: string, g?: Granularity) {
    const lengthToTakeIntoAccount = dateFnsSchema.replace(/'/g, "").length;
    return pipe(
      input.slice(0, lengthToTakeIntoAccount),
      (input) =>
        fns.parse(input, dateFnsSchema, utcToZonedTime(new Date(), "UTC")),
      (d) => formatTz(d, D_FORMAT, { timeZone: "UTC" }),
      (d) =>
        new LocalDateTime(d, g || lengthToGranularity(lengthToTakeIntoAccount))
    );
  }

  get granularity(): G {
    return this.g;
  }

  add(duration: DurationLikeObject) {
    return this.clone((d) => DateTime.fromJSDate(d).plus(duration).toJSDate());
  }

  diff(other: LocalDateTime<Granularity>) {
    if (other.granularity !== this.granularity) {
      throw new Error("Cannot diff dates with different granularity");
    }
    const differenceInMilliseconds =
      this.asUTCDate().getTime() - other.asUTCDate().getTime();
    return Duration.fromMillis(differenceInMilliseconds);
  }

  asUtcDateString() {
    return this.d;
  }

  static build(s: YearDate): LocalDateTime<"year">;
  static build(s: MonthDate): LocalDateTime<"month">;
  static build(s: DayDate): LocalDateTime<"day">;
  static build(s: HourDate): LocalDateTime<"hour">;
  static build(s: MinuteDate): LocalDateTime<"minute">;
  static build(s: SecondDate): LocalDateTime<"second">;
  static build(s: MillisecondDate): LocalDateTime<"millisecond">;
  static build(s: StringDateInput): LocalDateTime<Granularity> {
    return this.buildFromString(s);
  }
  static buildFromString<G extends Granularity>(
    s: string,
    g?: G,
    format?: string
  ) {
    if (format) {
      return this.parse(format, s, g);
    }
    if (!isStringDateAsUtc(s)) {
      throw new Error("Invalid date string");
    }

    if (!isValid(s, format)) {
      throw new Error("Invalid date string");
    }

    if (g) {
      return new LocalDateTime(s.slice(0, granularityToLength(g)), g);
    }

    let theS = s;
    // remove timezone information
    theS = theS.replace(/(\+\d{2}\:\d{2}|Z)/g, "");

    // if (theS.length > 23) {
    //   theS = theS.slice(0, 23);
    // }

    // 2024-01-23T21:10:00.000+01:00
    // 2024-01-23T21:10:00+01:00
    // 2024-01-23T21:10+01:00
    // 2024-01-23T21+01:00
    // 2024-01-23+01:00
    // 2024-01-23T21:10:00.000
    // 2024-01-23T21:10:00
    // 2024-01-23T21:10
    // 2024-01-23T21
    // 2024-01-23
    // 2024-01
    // 2024

    switch (theS.length) {
      case 4:
      case 7:
      case 10:
      case 13:
      case 16:
      case 19:
      case 23:
        return new LocalDateTime(theS, lengthToGranularity(theS.length));
      default:
        throw new Error(
          `Cannot guess granularity for date string: ${s}` as const
        );
    }
  }

  private clone(dateFn: (d: Date) => Date) {
    return pipe(
      utcToZonedTime(fns.parseISO(this.d), "UTC"),
      dateFn,
      (d) =>
        formatTz(d, D_FORMAT, {
          timeZone: "Z",
        }),
      (utcDateAsLocal) => new LocalDateTime(utcDateAsLocal, this.g)
    );
  }

  private asUTCDate() {
    return fns.parseISO(this.d);
  }

  format(dateFnsFormat?: string | undefined, locale?: "fr" | "en") {
    return pipe(
      utcToZonedTime(this.asUTCDate(), "UTC"),
      (d) =>
        formatTz(d, dateFnsFormat || "yyyy-MM-dd'T'HH:mm:ss.SSS", {
          timeZone: "Z",
          ...(locale === "fr" ? { locale: fr } : {}),
        }),
      (s) => (dateFnsFormat ? s : s.slice(0, granularityToLength(this.g)))
    );
  }
}

export const isOrBelow = <G extends Granularity>(
  granularity: Granularity,
  referenceGranularity: G
): granularity is OrBelow<G> => {
  return (
    granularityToLength(granularity) >=
    granularityToLength(referenceGranularity)
  );
};

export namespace ThirtyMinutesWindow {
  export const fromDateString = (s: string) => {
    // s is like "YYYY-MM-DDTHH:00" or "YYYY-MM-DDTHH:30"
    const [date, time] = s.split("T");
    const [year, month, day] = date.split("-");
    const [hour, minute] = time.split(":");
    return new Date(
      parseInt(year),
      parseInt(month) - 1,
      parseInt(day),
      parseInt(hour),
      parseInt(minute)
    );
  };

  export const schema = S.String;
}

export namespace ThirtyMinutesConsumptionData {
  export const schema = S.Struct({
    start: ThirtyMinutesWindow.schema,
  });
}
